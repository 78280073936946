import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        tk: null,
        company_name: null,
        linkman: null,
        uid: null,
        companyIntroduction: null,
    },
    mutations: {
        increment(state, data) {
            state.tk = data;
        },
        createcompany_name(state, data) {
            state.company_name = data
        },
        createlinkman(state, data) {
            state.linkman = data
        },
        createUid(state, data) {
            state.uid = data
        },
        createcCmpanyIntroduction(state, data) {
            state.companyIntroduction = data
        },
    },
    actions: {
        //   incrementAsync({ commit }) {
        //     setTimeout(() => {
        //       commit('increment');
        //     }, 1000);
        //   }
    },
    plugins: [createPersistedState({
        expires: 1000 * 60 * 60 * 2, // 1小时 = 1000毫秒 * 60秒 * 60分
    })]
});


export default store